import { useState } from "react";
import { useCryptoData } from "../hooks/useCryptoData";
import { ResultCard } from "../components/ResultCard";
import { CryptoForm } from "../components/CryptoForm";

function App() {
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [selectedCrypto, setSelectedCrypto] = useState("bitcoin");

  const { data, isLoading, error } = useCryptoData({
    amount,
    date,
    selectedCrypto,
  });

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      <div className="container mx-auto px-4 py-12">
        <div className="flex flex-col items-center max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold text-center mb-2">
            Imagine if you bought it earlier
          </h1>
          <p className="text-gray-400 text-center mb-12">
            Calculate your potential crypto returns from the past
          </p>

          <CryptoForm
            amount={amount}
            date={date}
            selectedCrypto={selectedCrypto}
            onAmountChange={setAmount}
            onDateChange={setDate}
            onCryptoChange={setSelectedCrypto}
          />

          {error && (
            <div className="mt-8 p-4 bg-red-500/20 rounded-lg text-red-200 text-center">
              {error.message}
            </div>
          )}

          {isLoading && (
            <div className="mt-8 text-center">
              <div className="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
            </div>
          )}

          {data && !isLoading && !error && (
            <div className="mt-8">
              <ResultCard
                initialInvestment={data.initialInvestment}
                currentValue={data.currentValue}
                profit={data.profit}
                profitPercentage={data.profitPercentage}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
