import { TrendingUp, TrendingDown } from "lucide-react";

interface ResultCardProps {
  initialInvestment: number;
  currentValue: number;
  profit: number;
  profitPercentage: number;
}

const formatCurrency = (value: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
  }).format(value);
};

const formatPercentage = (value: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value / 100);
};

export function ResultCard({
  initialInvestment,
  currentValue,
  profit,
  profitPercentage,
}: ResultCardProps) {
  const isProfit = profit >= 0;

  return (
    <div className="w-full max-w-md p-6 bg-gray-800 rounded-lg shadow-xl">
      <div className="space-y-4">
        <div className="flex gap-5 items-center justify-between">
          <span className="text-gray-400">Initial Investment</span>
          <span className="text-lg font-semibold">
            {formatCurrency(initialInvestment)}
          </span>
        </div>

        <div className="flex gap-5 items-center justify-between">
          <span className="text-gray-400">Current Value</span>
          <span className="text-lg font-semibold">
            {formatCurrency(currentValue)}
          </span>
        </div>

        <div className="border-t border-gray-700 my-4" />

        <div className="flex gap-5 items-center justify-between">
          <span className="text-gray-400">Profit/Loss</span>
          <div className="flex items-center gap-2">
            {isProfit ? (
              <TrendingUp className="w-5 h-5 text-green-500" />
            ) : (
              <TrendingDown className="w-5 h-5 text-red-500" />
            )}
            <span
              className={`text-xl font-bold ${
                isProfit ? "text-green-500" : "text-red-500"
              }`}
            >
              {formatCurrency(profit)}
            </span>
          </div>
        </div>

        <div className="flex gap-5 items-center justify-between">
          <span className="text-gray-400">Return</span>
          <span
            className={`text-lg font-semibold ${
              isProfit ? "text-green-500" : "text-red-500"
            }`}
          >
            {formatPercentage(profitPercentage)}
          </span>
        </div>
      </div>
    </div>
  );
}
