import { Bitcoin, DollarSign, Calendar } from "lucide-react";

interface CryptoFormProps {
  amount: string;
  date: string;
  selectedCrypto: string;
  onAmountChange: (value: string) => void;
  onDateChange: (value: string) => void;
  onCryptoChange: (value: string) => void;
}

export function CryptoForm({
  amount,
  date,
  selectedCrypto,
  onAmountChange,
  onDateChange,
  onCryptoChange,
}: CryptoFormProps) {
  return (
    <div className="space-y-6 w-full max-w-md">
      <div className="relative">
        <label
          htmlFor="amount"
          className="block text-sm font-medium text-gray-300 mb-2"
        >
          Investment Amount
        </label>
        <div className="relative">
          <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5" />
          <input
            id="amount"
            type="number"
            value={amount}
            onChange={(e) => onAmountChange(e.target.value)}
            className="w-full pl-10 pr-4 py-2 bg-gray-800 border border-gray-700 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100"
            placeholder="Enter amount"
          />
        </div>
      </div>

      <div className="relative">
        <label
          htmlFor="date"
          className="block text-sm font-medium text-gray-300 mb-2"
        >
          Investment Date
        </label>
        <div className="relative w-full">
          <Calendar className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5" />
          <input
            id="date"
            type="date"
            value={date}
            onChange={(e) => onDateChange(e.target.value)}
            className="w-full pl-10 pr-4 py-2 bg-gray-800 border border-gray-700 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 max-md:w-[92vw]"
            max={new Date().toISOString().split("T")[0]}
          />
        </div>
      </div>

      <div className="relative">
        <label
          htmlFor="crypto"
          className="block text-sm font-medium text-gray-300 mb-2"
        >
          Cryptocurrency
        </label>
        <div className="relative">
          <Bitcoin className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5" />
          <select
            id="crypto"
            value={selectedCrypto}
            onChange={(e) => onCryptoChange(e.target.value)}
            className="w-full pl-10 pr-4 py-2 bg-gray-800 border border-gray-700 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 appearance-none"
          >
            <option value="bitcoin">Bitcoin (BTC)</option>
            <option value="ethereum">Ethereum (ETH)</option>
            <option value="dogecoin">Dogecoin (DOGE)</option>
          </select>
        </div>
      </div>
    </div>
  );
}
