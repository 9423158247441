import { useQuery } from "@tanstack/react-query";
import { CryptoQueryParams, PriceData } from "../types";
import { cryptoAPI, CRYPTO_API_BASE_URL } from "../api";

export const useCryptoData = ({ selectedCrypto, date, amount }: CryptoQueryParams) => {
  return useQuery({
    queryKey: ["cryptoData", selectedCrypto, date],
    queryFn: async () => {
      const timestamp = date ? new Date(date).getTime() / 1000 : 0;
      const currentDate = new Date().getTime() / 1000;
      
      const response = await cryptoAPI.fetchPriceData(
        `${CRYPTO_API_BASE_URL}/coins/${selectedCrypto}/market_chart/range?vs_currency=usd&from=${timestamp}&to=${currentDate}`
      );
      
      const data = response as PriceData;
      const [initialPrice] = data.prices[0] || [0];
      const [currentPrice] = data.prices[data.prices.length - 1] || [0];
      const parsedAmount = parseFloat(amount || "0");
      
      return {
        initialInvestment: parsedAmount,
        currentValue: (currentPrice / initialPrice) * parsedAmount,
        profit: ((currentPrice / initialPrice) * parsedAmount) - parsedAmount,
        profitPercentage: (((currentPrice / initialPrice) * parsedAmount) - parsedAmount) / parsedAmount * 100,
        data
      };
    },
    enabled: Boolean(date && selectedCrypto && amount)
  });
};