export const CRYPTO_API_BASE_URL = "https://api.coingecko.com/api/v3";

export const cryptoAPI = {
  async fetchPriceData(url: string) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      throw new Error(`Failed to fetch price data: ${error}`);
    }
  },
};
